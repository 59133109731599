<template>
  <div class="d-flex flex-grow-1">
    <v-autocomplete
      v-model="selection"
      :disabled="isUpdating || loading"
      :items="items"
      :loading="loading"
      filled
      chips
      outlined
      dense
      color="blue-grey lighten-2"
      background-color="white"
      :label="label"
      item-text="_id"
      item-value="_id"
      :multiple="multiple"
      @change="handleChange"
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.item_id"
          :input-value="data.selected"
          close
          small
          dense
          style="margin: 5px 0 0 0"
          class="chip-overflow"
          @click="data.select"
          @click:close="remove(data.item)"
        >
          <span class="text-truncate">{{ data.item._id }}</span>
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <!-- <v-checkbox dense hide-details
      :label="`${data.item._id} (${data.item.count})`"
    ></v-checkbox> -->
          <!-- {{data.item._id}} ({{data.item.count}}) -->
          <v-list-item-content
            >{{ data.item._id }} ({{ data.item.count }})</v-list-item-content
          >
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
export default {
  props: {
    currentFilter: Object,
    items: Array,
    filter: String,
    label: String,
    loading: Boolean,
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selection: this.currentFilter[this.filter] || [],
      selected: false,
      isUpdating: false,
    };
  },
  methods: {
    remove(item) {
      const index = this.selection.indexOf(item._id);
      if (index >= 0) this.selection.splice(index, 1);
      this.$emit("update", {
        category: this.filter,
        values: [...this.selection],
      });
    },
    handleChange(values) {
      this.$emit("update", { category: this.filter, values: [...values] });
    },
  },
};
</script>
<style lang="scss">
.chip-overflow {
  max-width: 170px !important;
  padding: 2px 5px !important;
}
::v-deep .v-chip__content {
  display: inline-block !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

::v-deep .v-chip__close {
  position: absolute !important;
  top: 5px !important;
  right: 0 !important;
  width: 24px !important;
}
.v-input__icon.v-input__icon--append {
  cursor: pointer !important;
}
</style>
