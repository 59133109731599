<template>
  <v-main app>
    <v-navigation-drawer
      clipped
      app
      fixed
      :permanent="$vuetify.breakpoint.mdAndUp"
      v-model="drawer"
      :style="$vuetify.breakpoint.mdAndUp ? 'z-index: 1' : 'z-index: 999999'"
    >
      <v-list class="mt-4">
        <v-list-item class="mb-2">
          <v-btn block color="success" @click="resetFilters"
            >Reset Filters</v-btn
          >
        </v-list-item>
        <v-list-item v-if="hasExclusiveProducts">
        <v-checkbox
          v-model="onlyExclusives"
          @change="toggleExclusives"
          label="Only Exclusives"
        ></v-checkbox>
        </v-list-item>
        <v-list-item>
          <v-text-field
            v-model="filters.searchText"
            label="Search"
            :disabled="loadingMenus"
            dense
            outlined
            clearable
            @click:clear="updateSearch()"
            @input="updateSearch"
          ></v-text-field>
        </v-list-item>
        <v-list-item>
          <auto-complete
            :key="componentKey"
            :loading="loadingMenus"
            :currentFilter="filters"
            :disabled="loadingMenus"
            :items="categories"
            filter="category"
            label="Categories"
            @update="updateFilter"
          ></auto-complete>
        </v-list-item>
        <v-list-item>
          <auto-complete
            :key="componentKey"
            :loading="loadingMenus"
            :currentFilter="filters"
            :disabled="loadingMenus"
            :items="artists"
            :filter="'artist'"
            label="Artists"
            @update="updateFilter"
          ></auto-complete>
        </v-list-item>
        <v-list-item>
          <auto-complete
            :key="componentKey"
            :currentFilter="filters"
            :loading="loadingMenus"
            :disabled="loadingMenus"
            :items="regions"
            filter="regions"
            label="Region"
            @update="updateFilter"
          ></auto-complete>
        </v-list-item>
        <v-list-item>
          <auto-complete
            :key="componentKey"
            :currentFilter="filters"
            :disabled="loadingMenus"
            :loading="loadingMenus"
            :items="subjectMatter"
            filter="subjectMatter"
            label="Subject Matter"
            @update="updateFilter"
          ></auto-complete>
        </v-list-item>
        <v-list-item>
          <auto-complete
            :key="componentKey"
            :currentFilter="filters"
            :loading="loadingMenus"
            :disabled="loadingMenus"
            :items="colours"
            filter="imageColour"
            label="Colours"
            @update="updateFilter"
          ></auto-complete>
        </v-list-item>
        <v-list-item v-if="greetingCardSize.length">
          <auto-complete
            :key="componentKey"
            :currentFilter="filters"
            :loading="loadingMenus"
            :disabled="loadingMenus"
            :items="greetingCardSize"
            filter="greetingCardSize"
            label="Greeting Card Size"
            @update="updateFilter"
          ></auto-complete>
        </v-list-item>
        <v-list-item v-if="mountedPrintSize.length">
          <auto-complete
            :key="componentKey"
            :currentFilter="filters"
            :disabled="loadingMenus"
            :loading="loadingMenus"
            :items="mountedPrintSize"
            filter="mountedPrintSize"
            label="Mounted Print Size"
            @update="updateFilter"
          ></auto-complete>
        </v-list-item>
        <!-- <v-list-item>
        <v-checkbox
          @click="togglePicks()"
          :disabled="picks.length === 0"
          :label="`Only picks (${picks.length})`"
        ></v-checkbox>
        </v-list-item> -->
      </v-list>
      <input-dialog ref="inputDialog" />
    </v-navigation-drawer>
    <v-container fluid>
        <v-app-bar-nav-icon

          style="left: 0; top: 0; position: absolute"
          class="hidden-md-and-up"
          @click.stop="toggleDrawer"
        ></v-app-bar-nav-icon>
      <products @toggleDrawer="toggleDrawer" />
    </v-container>
  </v-main>
</template>

<script>
import AutoComplete from "@/components/AutoComplete.vue";
// import CheckboxArray from '@/components/CheckboxArray.vue'
import InputDialog from "@/components/InputDialog.vue";
import api from "@/api/product"
import Products from "@/views/Common/Products/Products.vue";
import { mapState, mapGetters } from "vuex";

const server = process.env.VUE_APP_API_URL || "";

export default {
  name: "PublicLayout",
  props: {
    sidebar: Boolean,
  },
  components: {
    AutoComplete,
    // CheckboxArray,
    Products,
    InputDialog,
  },
  data() {
    return {
      componentKey: 0,
      drawer: this.sidebar,
      random: +new Date(),
      artists: [],
      categories: [],
      regions: [],
      colours: [],
      subjectMatter: [],
      greetingCardSize: [],
      mountedPrintSize: [],
      onlyExclusives: false,
      evt: null,
      loadingMenus: true,
    };
  },
  async created() {
      api.loadAggregates({
        filter: { ...this.filters, exclusive: this.onlyExclusives },
      })
      .then(({ data }) => {
        this.artists = data.artists;
        this.categories = data.categories;
        this.regions = data.regions;
        this.colours = data.colours;
        this.subjectMatter = data.subjectMatter;
        this.greetingCardSize = data.greetingCardSize;
        this.mountedPrintSize = data.mountedPrintSize;
        this.loadingMenus = false;
      });
  },
  computed: mapState({
    ...mapGetters("filters", ["filters"]),
    ...mapGetters(["hasExclusiveProducts"]),
    onlyGreetingCards() {
      return this.$store.getters["filters/onlyGreetingCards"];
    },
    // filters: state => state.filters.all,
    onlyPicks: (state) => state.filters.onlyPicks,
    picks: (state) => state.products.picks,
    // onlyGreetingCards = state.filters.all.greetingCardSize.length === 1 && filter["category"][0]  === 'Greeting Cards'
  }),
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    togglePicks() {
      this.$store.commit("filters/setOnlyPicks", !this.onlyPicks);
    },
    toggleExclusives(val) {
      this.updateFilter({ category: "exclusive", values: val });
    },
    updateSearch(val) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.$store.commit("filters/setSearchText", val);
        this.filtersDirty = true;
        api.loadAggregates({
          filter: { ...this.filters, exclusive: this.onlyExclusives },
        })
        .then(({ data }) => {
          this.artists = data.artists;
          this.categories = data.categories;
          this.regions = data.regions;
          this.colours = data.colours;
          this.subjectMatter = data.subjectMatter;
          this.greetingCardSize = data.greetingCardSize;
          this.mountedPrintSize = data.mountedPrintSize;
        });
      }, 400);
    },
    updateFilter(val) {
      const obj = {};
      obj[val.category] = val.values;
      this.filtersDirty = true;
      this.$store.dispatch("filters/setFilters", obj);
      api.loadAggregates({
        filter: { ...this.filters, exclusive: this.onlyExclusives },
      })
      .then(({ data }) => {
        this.artists = data.artists;
        this.categories = data.categories;
        this.regions = data.regions;
        this.colours = data.colours;
        this.subjectMatter = data.subjectMatter;
        this.greetingCardSize = data.greetingCardSize;
        this.mountedPrintSize = data.mountedPrintSize;
      });
    },
    resetFilters() {
      this.$store.commit("filters/resetFilters");
      this.componentKey += 1;
      api.loadAggregates({
        filter: { ...this.filters, exclusive: this.onlyExclusives },
      })
      .then(({ data }) => {
        this.artists = data.artists;
        this.categories = data.categories;
        this.regions = data.regions;
        this.colours = data.colours;
        this.subjectMatter = data.subjectMatter;
        this.greetingCardSize = data.greetingCardSize;
        this.mountedPrintSize = data.mountedPrintSize;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// .sidebar {
//   margin-top: 112px !important;
// }
</style>
